import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/index'),
    redirect: '/home/workplaceInformation',
    children: [
      {
        path: '/home/workplaceInformation',
        component: () => import('@/views/Home/Children/WorkplaceInformation'),
        hidden: true,
      },
      {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
      },
      {
        path: '/register',
        component: () => import('@/views/register/index')
      },
      {
        path: '/home/workplaceInformation/workDetail',
        component: () => import('@/views/Home/Children/WorkplaceInformation/children/workDetail')
      },
      {
        path: '/home/workplaceInformation/personnelDetail',
        component: () => import('@/views/Home/Children/WorkplaceInformation/children/personnelDetail')
      }
    ]
  },

]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
